// Import the functions you need from the SDKs you need
import { initializeApp, firebase } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { ref, get } from "firebase/database";
import { set, child } from "firebase/database";
import "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const FIREBASE_APi_KEY = process.env.REACT_APP_FIREBASE_APi_KEY;
const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: FIREBASE_APi_KEY, //GOOGLE_MAPS_API_KEY,
  authDomain: "qnpdp-afc87.firebaseapp.com",
  projectId: "qnpdp-afc87",
  storageBucket: "qnpdp-afc87.appspot.com",
  messagingSenderId: "842683344158",
  appId: FIREBASE_APP_ID, //FIREBASE_APP_ID, //FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account ",
});

export const getLocations = async () => {
  const locationsRef = ref(db, "locations");
  const snapshot = await get(locationsRef);
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    console.log("No data available");
    return null;
  }
};

export const auth = getAuth();
export const db = getDatabase(firebaseApp);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export const createUserProfileDocument = async (userAuth) => {
  if (!userAuth) return;

  const db = getDatabase();
  const userRef = ref(db, `users/${userAuth.uid}`);

  try {
    const snapshot = await get(userRef);

    if (!snapshot.exists()) {
      const { displayName, email } = userAuth;
      const createdAt = new Date();

      await set(userRef, {
        displayName,
        email,
        createdAt,
      });
    } else {
      // Update data if necessary
    }
  } catch (error) {
    console.error("Error al crear el perfil de usuario:", error);
    throw new Error(
      "No se pudo guardar la información del usuario en la base de datos."
    );
  }
};
