// src/contexts/UserContext.js
import { createContext, useState, useEffect } from "react";
import {
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  signOut,
} from "firebase/auth";
import { auth } from "../utils/firebase.utils";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const setUpUserPersistence = async () => {
      try {
        await setPersistence(auth, browserSessionPersistence);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setCurrentUser(user);
            localStorage.setItem("user", JSON.stringify(user)); // Store user data in local storage
          } else {
            setCurrentUser(null);
            localStorage.removeItem("user");
          }
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error setting up user persistence:", error);
      }
    };

    setUpUserPersistence();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      localStorage.removeItem("user");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <UserContext.Provider value={{ currentUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};
