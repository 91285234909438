import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "./images/logo.png";
import EncontraCancha from "./components/encontraCancha";
import QuieroJugar from "./components/quieroJugar";
import CargarCancha from "./components/cargarCancha";
import SignIn from "./components/sign-in";
import { UserProvider, UserContext } from "./contexts/userContext";
import Tooltip from "@mui/material/Tooltip";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Importa el hook de navegación

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// Personalizar Tabs y Tab utilizando styled
const CustomTabs = styled(Tabs)({
  backgroundColor: "black",
  minHeight: "70px",
  "& .MuiTabs-indicator": {
    backgroundColor: "#EC5306",
  },
});

const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme, disabled }) => ({
    textTransform: "none",
    minHeight: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 25,
    color: disabled ? "#a6a6a6" : "#EDEAEA",
    "&.Mui-selected": {
      color: disabled ? "#a6a6a6" : "#EC5306",
    },
    "&.Mui-disabled": {
      color: disabled ? "#a6a6a6" : "#EC5306",
    },
    "&:hover": {
      color: disabled ? "#a6a6a6" : "#EC5306",
      opacity: 1,
    },
  })
);

const DrawerItem = styled(Box)(({ theme, active, disabled }) => ({
  padding: theme.spacing(2),
  color: disabled ? "#808080" : active ? "#EC5306" : "#EDEAEA",
  cursor: disabled ? "not-allowed" : "pointer",
  "&:hover": {
    color: disabled ? "#808080" : "#EC5306",
  },
}));

export default function App() {
  const [tabNameValue, setTabNameValue] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [canchaId, setCanchaId] = React.useState(null);
  const isMobile = useMediaQuery("(max-width:600px)");
  const containerRef = useRef(null);

  //const [searchParams, setSearchParams] = useSearchParams();
  const [searchParams] = useSearchParams();

  //let canchaId = searchParams.get("canchaId");

  const { currentUser } = useContext(UserContext);
  /*
  useEffect(() => {
    //setCanchaId(searchParams.get("canchaId"));
    // const queryString = window.location.search;
    //const urlParams = new URLSearchParams(queryString);
    // setCanchaId(urlParams.get("canchaId"));
  }, []);
*/
  useEffect(() => {
    const canchaIdParam = searchParams.get("canchaId");
    //console.log("canchaId: " + canchaIdParam);
    setCanchaId(canchaIdParam);
  }, [searchParams]);

  const handleChange = (event, newValue) => {
    setTabNameValue(newValue);
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  // Scroll to top effect
  useEffect(() => {
    if (tabNameValue === 0) {
      window.scrollTo({
        top: -20,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [tabNameValue]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Función para cambiar a la primera tab desde cualquier componente
  const switchToFirstTab = () => {
    setTabNameValue(0);
  };

  const navigate = useNavigate(); // Hook para navegar

  const handleLogoClick = () => {
    navigate("/"); // Redirige a la página principal
    window.parent.location = window.parent.location.href;
  };

  const renderTabs = () => (
    <CustomTabs
      value={tabNameValue}
      onChange={handleChange}
      aria-label="basic tabs example"
    >
      <CustomTab label="Encontra cancha" {...a11yProps(0)} />

      <CustomTab
        label="Quiero jugar"
        {...a11yProps(1)}
        disabled={!currentUser}
      />
      <CustomTab
        label="Cargar cancha"
        {...a11yProps(2)}
        disabled={!currentUser}
      />
      <Box style={{}}>
        <SignIn></SignIn>
      </Box>
    </CustomTabs>
  );

  const renderDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          backgroundColor: "black",
        },
      }}
    >
      <Box sx={{ width: 250, padding: 2 }}>
        <DrawerItem
          active={tabNameValue === 0}
          onClick={(event) => handleChange(event, 0)}
        >
          Encontra cancha
        </DrawerItem>
        <Tooltip
          title={
            !currentUser
              ? "Por favor, inicia sesión para acceder a esta sección."
              : ""
          }
          arrow
        >
          <span>
            <DrawerItem
              active={tabNameValue === 1}
              onClick={(event) =>
                !currentUser ? null : handleChange(event, 1)
              }
              disabled={!currentUser}
            >
              Quiero jugar
            </DrawerItem>
          </span>
        </Tooltip>

        <Tooltip
          title={
            !currentUser
              ? "Por favor, inicia sesión para acceder a esta sección."
              : ""
          }
          arrow
        >
          <span>
            <DrawerItem
              active={tabNameValue === 2}
              onClick={(event) =>
                !currentUser ? null : handleChange(event, 2)
              }
              disabled={!currentUser}
            >
              Cargar cancha
            </DrawerItem>
          </span>
        </Tooltip>

        <SignIn></SignIn>
      </Box>
    </Drawer>
  );

  return (
    <Box sx={{ width: "100%", backgroundColor: "black" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: 1,
          borderColor: "gray",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ height: "60px", paddingLeft: "20px", paddingRight: "20px" }}
          onClick={handleLogoClick}
        />
        <Box
          sx={{
            height: "70px",
            borderLeft: "1px solid gray",
            marginRight: "16px",
          }}
        />
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="#EC5306"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{
                color: "#EC5306",
                marginLeft: "55%",
              }}
            >
              <MenuIcon color="#EC5306" />
            </IconButton>
            {renderDrawer()}
          </>
        ) : (
          renderTabs()
        )}
      </Box>
      <CustomTabPanel value={tabNameValue} index={0}>
        <EncontraCancha canchaId={canchaId} />
      </CustomTabPanel>
      <CustomTabPanel value={tabNameValue} index={1}>
        <QuieroJugar switchToFirstTab={switchToFirstTab} />
      </CustomTabPanel>
      <CustomTabPanel value={tabNameValue} index={2}>
        <CargarCancha></CargarCancha>
      </CustomTabPanel>
    </Box>
  );
}
