import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  MapCameraChangedEvent,
  Pin,
  Marker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getLocations } from "../utils/firebase.utils";
import { FaStar } from "react-icons/fa";
import { GiBasketballBasket } from "react-icons/gi";
import { Padding } from "@mui/icons-material";
import { db } from "../utils/firebase.utils";
import { onValue, ref, update } from "firebase/database";
import SuccessModal from "./successModal.js"; // Asegúrate de importar el modal
// Personalizar el componente Select
const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "#C7C7C7",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306",
  },
  "& .Mui-focused .MuiSelect-select": {
    backgroundColor: "#EC5306",
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.Mui-focused": {
    color: "#EC5306",
  },
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fill: "gray",
    fontSize: 28,
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    fill: "#EC5306",
  },
  "&.Mui-checked": {
    color: "#EC5306",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#EC5306"),
  backgroundColor: "#EC5306",
  "&:hover": {
    backgroundColor: "#EC5306",
  },
  borderRadius: 10,
  height: 50,
  marginTop: 60,
  marginBottom: 150,
  width: "100%",
}));

const OrangeFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: "#EC5306", // O usa un color específico como '#FFA500' (naranja)
}));

export default function QuieroJugar({ switchToFirstTab }) {
  const [cantJugadores, setCantJugadores] = React.useState("");
  const [nivExp, setNivExp] = React.useState("");
  const [cuandoJugas, setCuandoJugas] = React.useState("");
  const [menuOpen, setMenuOpen] = React.useState(true);

  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [errors, setErrors] = useState({});
  const [gralSelectedPlace, setGralSelectedPlace] = useState(null);

  const [open, setOpen] = useState(false);
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const handleButtonClick = async () => {
    await handleUpdateRecord();
  };

  const handleClose = () => {
    setOpen(false);

    // Redirigir a la primera tab
    switchToFirstTab();
  };

  useEffect(() => {
    const fetchLocations = async () => {
      const data = await getLocations();
      if (data) {
        // Transform the object to an array
        const locationsArray = Object.keys(data).map((key) => ({
          key: key,
          ...data[key],
        }));
        setLocations(locationsArray);
        setFilteredLocations(locationsArray); // Inicialmente sin filtros
      }
    };

    fetchLocations();
  }, []);

  const handleChangeCantJugadores = (event) => {
    setCantJugadores(event.target.value);
  };

  const handleChangeNivExp = (event) => {
    setNivExp(event.target.value);
  };

  const handleChangeCuandoJugas = (event) => {
    setCuandoJugas(event.target.value);
  };

  const handleUpdateRecord = async () => {
    if (handleValidation()) {
      const referencia = ref(db, "locations/" + gralSelectedPlace.key);
      const genteJugandoData = {
        genteActiva: gralSelectedPlace.genteActiva + cantJugadores,
      };

      update(referencia, genteJugandoData)
        .then(() => {
          setOpen(true);
          console.log("Datos actualizados correctamente.");
        })
        .catch((error) => {
          console.error("Error actualizando datos: ", error);
        });
    }
  };

  const handleValidation = () => {
    let formErrors = {};
    let isValid = true;
    if (!cantJugadores) {
      formErrors.cantJugadores = "Completa la cantidad de jugadores";
      isValid = false;
    }

    if (!nivExp) {
      formErrors.nivExp = "Completa su nivel de experiencia";
      isValid = false;
    }

    if (!cuandoJugas) {
      formErrors.cuandoJugas = "Completa cuando quieres jugar";
      isValid = false;
    }

    if (!gralSelectedPlace) {
      formErrors.notSelectedPlace = "Selecciona la cancha donde quieres jugar";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const PoiMarkers = ({ pois }) => {
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [isMouseOverInfoWindow, setIsMouseOverInfoWindow] = useState(false);
    const [closeTimeout, setCloseTimeout] = useState(null);

    const handleMouseOver = (poi) => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
      setSelectedPlace(poi);
    };

    const handleMouseOut = () => {
      if (!isMouseOverInfoWindow) {
        const timeout = setTimeout(() => {
          setSelectedPlace(null);
        }, 800);
        setCloseTimeout(timeout);
      }
    };

    const handleClick = (poi) => {
      setSelectedPlace(poi);
      setGralSelectedPlace(poi);
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
    };

    const handleInfoWindowMouseOver = () => {
      setIsMouseOverInfoWindow(true);
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
    };

    const handleInfoWindowMouseOut = () => {
      setIsMouseOverInfoWindow(false);
      const timeout = setTimeout(() => {
        setSelectedPlace(null);
      }, 500);
      setCloseTimeout(timeout);
    };

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const renderStars = (rate) => {
      const stars = [];
      for (let i = 0; i < 5; i++) {
        if (rate >= 1) {
          stars.push(<FaStar key={i} color="#EC5306" />);
          rate -= 1;
        } else if (rate > 0) {
          stars.push(
            <FaStar key={i} color="#EC5306" style={{ opacity: rate }} />
          );
          rate = 0;
        } else {
          stars.push(<FaStar key={i} color="#CCCCCC" />);
        }
      }
      return stars;
    };

    const renderBasketballHoops = (cantAros) => {
      const hoops = [];
      for (let i = 0; i < cantAros; i++) {
        hoops.push(<GiBasketballBasket key={i} color="#EC5306" size={25} />);
      }
      return hoops;
    };

    useEffect(() => {
      return () => {
        if (closeTimeout) {
          clearTimeout(closeTimeout);
        }
      };
    }, [closeTimeout]);

    return (
      <>
        {pois.map((poi) => (
          <AdvancedMarker
            key={poi.key}
            position={poi.location}
            onMouseOver={() => handleMouseOver(poi)}
            onMouseOut={handleMouseOut}
            onClick={() => handleClick(poi)}
          >
            <Pin
              background={"#151414"}
              glyphColor={"#EC5306"}
              borderColor={"#EC5306"}
            />
          </AdvancedMarker>
        ))}
        {selectedPlace && (
          <InfoWindow
            position={selectedPlace.location}
            onCloseClick={() => setSelectedPlace(null)}
            onMouseOver={handleInfoWindowMouseOver}
            onMouseOut={handleInfoWindowMouseOut}
            style={{ width: "100%", overflowX: "hidden" }}
          >
            <div style={{ width: "100%" }}>
              {selectedPlace.images.length > 1 ? (
                <Slider {...sliderSettings}>
                  {selectedPlace.images.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        alt={`Slide ${index}`}
                        style={{
                          maxWidth: "100%", // La imagen no excederá el ancho del contenedor
                          maxHeight: "40vh", // Establece la altura máxima
                          width: "auto", // La imagen mantiene su proporción
                          height: "auto", // La imagen mantiene su proporción
                          objectFit: "cover", // Cubre el espacio disponible sin distorsionar
                          borderRadius: 8, // Bordes redondeados opcionales
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <div>
                  <img
                    src={selectedPlace.images[0]}
                    alt="Single Image"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "40vh",
                      width: "auto",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: 8,
                    }}
                  />
                </div>
              )}
              <h2>{selectedPlace.nombre}</h2>
              <p>
                <strong>Descripción:</strong> {selectedPlace.comentarios}
              </p>
              <p>
                <strong>Rating:</strong> {renderStars(selectedPlace.rate)}
              </p>
              <p>
                <strong>Cantidad de aros:</strong>{" "}
                {renderBasketballHoops(selectedPlace.cantAros)}
              </p>
              <p>
                <strong>Abierta siempre:</strong>{" "}
                {selectedPlace.abiertaSiempre ? "Sí" : "No"}
              </p>
              <p>
                <strong>Gente activa:</strong> {selectedPlace.genteActiva}
              </p>
              <p>
                <strong>Tiene cancha completa?</strong>{" "}
                {selectedPlace.canchaCompleta ? "Sí" : "No"}
              </p>
              <p
                style={
                  selectedPlace.comunidadData
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Instagram/Wpp: </strong>
                {selectedPlace.comunidadData}
              </p>
              <p
                style={
                  selectedPlace.extraInfo
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Informacion Extra: </strong>
                {selectedPlace.extraInfo}
              </p>
              <a
                style={
                  selectedPlace.google_photos_url
                    ? {
                        display: "block",
                        textDecoration: "none",
                        color: "#EC5306",
                      }
                    : { display: "none" }
                }
                href={selectedPlace.google_photos_url}
                target="_blank"
              >
                Ver mas fotos
              </a>
            </div>
          </InfoWindow>
        )}
      </>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: { xs: menuOpen ? "flex" : "none", md: "flex" },
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          p: 4,
          bgcolor: "black",
          color: "white",
        }}
      >
        {/* Versión Mobile */}
        <Accordion
          sx={{
            display: { xs: "block", md: "none" },
            width: "100%",
            backgroundColor: "black",
            color: "#DFDCDC",
            borderBottom: 1,
            borderColor: "gray",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#DFDCDC" }} />}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              INFORMACION DEL JUEGO
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth error={!!errors.cantJugadores}>
              <CustomInputLabel id="cant-jugadores-select-label">
                CANTIDAD DE JUGADORES
              </CustomInputLabel>
              <CustomSelect
                labelId="cant-jugadores-select-label"
                id="cant-jugadores-select"
                value={cantJugadores}
                label="CANTIDAD_JUGADORES"
                onChange={handleChangeCantJugadores}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </CustomSelect>
              {errors.cantJugadores && (
                <OrangeFormHelperText>
                  {errors.cantJugadores}
                </OrangeFormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }} error={!!errors.nivExp}>
              <CustomInputLabel id="nivel-exp-select-label">
                NIVEL DE EXPERIENCIA
              </CustomInputLabel>
              <CustomSelect
                labelId="niv-exp-select-label"
                id="niv-exp-select"
                value={nivExp}
                label="NIVEL_EXPERIENCIA"
                onChange={handleChangeNivExp}
              >
                <MenuItem value={"princpipiante"}>Principiante</MenuItem>
                <MenuItem value={"intermedio"}>Intermedio</MenuItem>
                <MenuItem value={"avanzado"}>Avanzado</MenuItem>
              </CustomSelect>
              {errors.nivExp && (
                <OrangeFormHelperText>{errors.nivExp}</OrangeFormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }} error={!!errors.cuandoJugas}>
              <CustomInputLabel id="cuando-jugas-select-label">
                CUANDO QUERES JUGAR
              </CustomInputLabel>
              <CustomSelect
                labelId="cuando-jugas-select-label"
                id="cuando-jugas-select"
                value={cuandoJugas}
                label="CUANDO_JUGAS"
                onChange={handleChangeCuandoJugas}
              >
                <MenuItem value={10}>Ahora</MenuItem>
                <MenuItem value={20}>En 1 hora</MenuItem>
                <MenuItem value={30}>En 2 horas</MenuItem>
              </CustomSelect>
              {errors.cuandoJugas && (
                <OrangeFormHelperText>
                  {errors.cuandoJugas}
                </OrangeFormHelperText>
              )}
            </FormControl>
          </AccordionDetails>
        </Accordion>

        {/* Versión Desktop */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              gap: 5,
              width: "70%",
            }}
          >
            <FormControl fullWidth error={!!errors.cantJugadores}>
              <CustomInputLabel id="cant-jugadores-select-label">
                CANTIDAD DE JUGADORES
              </CustomInputLabel>
              <CustomSelect
                labelId="cant-jugadores-select-label"
                id="cant-jugadores-select"
                value={cantJugadores}
                label="CANTIDAD_JUGADORES"
                onChange={handleChangeCantJugadores}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </CustomSelect>
              {errors.cantJugadores && (
                <OrangeFormHelperText>
                  {errors.cantJugadores}
                </OrangeFormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={!!errors.nivExp}>
              <CustomInputLabel id="nivel-exp-select-label">
                NIVEL DE EXPERIENCIA
              </CustomInputLabel>
              <CustomSelect
                labelId="niv-exp-select-label"
                id="niv-exp-select"
                value={nivExp}
                label="NIVEL_EXPERIENCIA"
                onChange={handleChangeNivExp}
              >
                <MenuItem value={"princpipiante"}>Principiante</MenuItem>
                <MenuItem value={"intermedio"}>Intermedio</MenuItem>
                <MenuItem value={"avanzado"}>Avanzado</MenuItem>
              </CustomSelect>
              {errors.nivExp && (
                <OrangeFormHelperText>{errors.nivExp}</OrangeFormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={!!errors.cuandoJugas}>
              <CustomInputLabel id="cuando-jugas-select-label">
                CUANDO QUERES JUGAR
              </CustomInputLabel>
              <CustomSelect
                labelId="cuando-jugas-select-label"
                id="cuando-jugas-select"
                value={cuandoJugas}
                label="CUANDO_JUGAS"
                onChange={handleChangeCuandoJugas}
              >
                <MenuItem value={10}>Ahora</MenuItem>
                <MenuItem value={20}>En 1 hora</MenuItem>
                <MenuItem value={30}>En 2 horas</MenuItem>
              </CustomSelect>
              {errors.cuandoJugas && (
                <OrangeFormHelperText>
                  {errors.cuandoJugas}
                </OrangeFormHelperText>
              )}
            </FormControl>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          width: "96%",
          marginLeft: "2%",
        }}
      >
        <p style={{ color: "#D9D8D8", fontWeight: "600" }}>
          SELECCIONA LA CANCHA DONDE QUERES JUGAR:{" "}
        </p>
        <p
          style={
            errors.notSelectedPlace
              ? { color: "red", fontWeight: "600" }
              : { display: "none" }
          }
        >
          Seleccione el lugar donde quiere jugar.{" "}
        </p>
        <div
          style={{
            width: "100%",
            height: "50vh",
            overflow: "hidden",
            borderRadius: 20,
          }}
        >
          <APIProvider
            apiKey={GOOGLE_MAPS_API_KEY}
            onLoad={() => console.log("Maps API has loaded.")}
          >
            <Map
              defaultZoom={13}
              defaultCenter={{ lat: -34.61315, lng: -58.37723 }}
              mapId={"8a45de67aeece53b"}
              onCameraChanged={(ev) =>
                console.log(
                  "camera changed:",
                  ev.detail.center,
                  "zoom:",
                  ev.detail.zoom
                )
              }
            >
              <PoiMarkers pois={locations} />
            </Map>
          </APIProvider>
        </div>
        <ColorButton variant="contained" onClick={handleButtonClick}>
          QUIERO JUGAR
        </ColorButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{"Actualizacion exitosa"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Se agregaron los jugadores a la cancha seleccionada
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="success">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}
