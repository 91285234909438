import { useState, useContext } from "react";
import { UserContext } from ".././contexts/userContext.js";
import {
  signInWithGooglePopup,
  createUserProfileDocument,
} from "../utils/firebase.utils";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Logout from "./logout.js"; // Import the Logout component

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#EC5306",
  backgroundColor: "black",
  "&:hover": {
    backgroundColor: "#EC5306",
  },
  borderRadius: 10,
  height: 40,
  width: "100%",
  border: "1px solid",
  borderColor: "#EC5306",
}));

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useContext(UserContext);

  const logGoogleUser = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await signInWithGooglePopup();

      if (!response) {
        throw new Error("No se pudo completar la autenticación.");
      }

      const { user } = response;

      if (!user) {
        throw new Error("No se pudo obtener la información del usuario.");
      }

      // Guardar usuario en Firebase Realtime Database
      await createUserProfileDocument(user);

      console.log("Usuario autenticado:", user);
    } catch (error) {
      if (error.message.includes("auth/popup-closed-by-user")) {
        setError(
          "El popup de Google se cerró antes de completar el inicio de sesión."
        );
      } else {
        setError("Error en la autenticación: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "20px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          {error}
        </Alert>
      )}
      {currentUser ? (
        <div style={{ color: "white", width: "100%" }}>
          <Logout />
        </div>
      ) : (
        //Bienvenido, {currentUser.displayName}!{" "}
        <ColorButton
          variant="contained"
          onClick={logGoogleUser}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            "Iniciar sesión con Google"
          )}
        </ColorButton>
      )}
    </Box>
  );
};

export default SignIn;
