import { useContext } from "react";
import { UserContext } from ".././contexts/userContext";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#EC5306",
  backgroundColor: "black",
  "&:hover": {
    backgroundColor: "#EC5306",
  },
  borderRadius: 10,
  height: 40,
  width: "100%",
  border: "1px solid",
  borderColor: "#EC5306",
}));

const Logout = () => {
  const { currentUser, logout } = useContext(UserContext);

  if (!currentUser) return null;

  return (
    <ColorButton variant="contained" onClick={logout}>
      Cerrar sesión
    </ColorButton>
  );
};

export default Logout;
