import React, { useState } from "react";

const HoverableButton = ({ href, children, style, openInNewTab }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsClicked(false);
  };

  const handleClick = (e) => {
    if (!href) {
      e.preventDefault(); // Si no hay href, prevenir la navegación
    }
    setIsClicked(true);
  };

  return (
    <a
      href={href || "#"} // Si no hay href, evita la redirección
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      target={openInNewTab ? "_blank" : undefined} // Agrega target _blank si openInNewTab es true
      rel={openInNewTab ? "noopener noreferrer" : undefined} // Buena práctica cuando se usa _blank
      style={{
        textDecoration: "none",
        textTransform: "uppercase",
        color: isHovered || isClicked ? "white" : "#EC5306",
        backgroundColor: isHovered || isClicked ? "#EC5306" : "white",
        marginTop: 10,
        marginBottom: 10,
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        borderRadius: 10,
        height: 30,
        width: "99%",
        border: "1.5px solid",
        borderColor: "#EC5306",
        fontSize: 13,
        fontWeight: "500",
        transition: "all 0.3s ease",
        cursor: "pointer", // El cursor será pointer siempre
        ...style, // Permitir sobreescribir estilos con la prop `style`
      }}
    >
      {children}
    </a>
  );
};

export default HoverableButton;
