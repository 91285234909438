import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
//import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  MapCameraChangedEvent,
  Pin,
  Marker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaStar } from "react-icons/fa";
import { GiBasketballBasket } from "react-icons/gi";
//import { Padding } from "@mui/icons-material";
//import { db } from "../utils/firebase.utils";
import { onValue, ref } from "firebase/database";
import { getLocations } from "../utils/firebase.utils";
//import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
//import { IoIosShareAlt } from "react-icons/io";
import HoverableButton from "../components/hoverableButton";

// Personalizar el componente Select
const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "#C7C7C7",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306",
  },
  "& .Mui-focused .MuiSelect-select": {
    backgroundColor: "#EC5306",
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.Mui-focused": {
    color: "#EC5306",
  },
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fill: "gray",
    fontSize: 28,
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    fill: "#EC5306",
  },
  "&.Mui-checked": {
    color: "#EC5306",
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#EC5306",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  borderRadius: 10,
  height: 30,
  width: "99%",
  border: "1.5px solid",
  borderColor: "#EC5306",
  marginTop: 10,
  fontSize: 12,
  elevation: 0,
}));

/*
const locations = [
  {
    key: "operaHouse",
    location: { lat: -34.618669, lng: -58.508316 },
    name: "Plaza San Pedro",
    description: "Famous opera house in the city",
    plaza: true,
    polideportivo: false,
    barrio: "Monte Castro",
    rate: 4.5,
    cantAros: 1,
    abiertaSiempre: false,
    genteActiva: 4,
    images: [
      "https://www.unocontraunoweb.com/wp-content/uploads/2019/06/Plaza-1-696x338.jpg",
      "https://www.unocontraunoweb.com/wp-content/uploads/2019/06/Plaza-2-696x338.jpg",
      // Agrega más URLs de imágenes
    ],
    comunidadData: "www.instagram.com/comunidad",
    extraInfo: "A veces hay muchos niños",
    canchaCompleta: false,
  },
  {
    key: "tarongaZoo",
    location: { lat: -34.645917, lng: -58.477499 },
    name: "Poli. Nicolas Avellaneda",
    plaza: false,
    polideportivo: true,
    barrio: "Parque Avellaneda",
    rate: 1,
    cantAros: 4,
    abiertaSiempre: false,
    genteActiva: 0,
    images: [
      "https://www.unocontraunoweb.com/wp-content/uploads/2019/06/Plaza-1-696x338.jpg",
      "https://www.unocontraunoweb.com/wp-content/uploads/2019/06/Plaza-2-696x338.jpg",
      // Agrega más URLs de imágenes
    ],
    canchaCompleta: true,
  },
  // Agrega más lugares según sea necesario
];
*/
/*
const PoiMarkers = (props) => {
  return (
    <>
      {props.pois.map((poi) => (
        <AdvancedMarker key={poi.key} position={poi.location}>
          <Pin
            background={"#151414"}
            glyphColor={"#EC5306"}
            borderColor={"#EC5306"}
          />
        </AdvancedMarker>
      ))}
    </>
  );
};
*/

const PoiMarkers = ({ pois }) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isMouseOverInfoWindow, setIsMouseOverInfoWindow] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState(null);
  const [copied, setCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsClicked(false); // Restablece el estado de clic al dejar de hacer hover
  };

  const handleMouseOver = (poi) => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
      setCloseTimeout(null);
    }
    setSelectedPlace(poi);
  };

  const handleMouseOut = () => {
    if (!isMouseOverInfoWindow) {
      const timeout = setTimeout(() => {
        setSelectedPlace(null);
      }, 800);
      setCloseTimeout(timeout);
    }
  };

  const handleClick = (poi) => {
    setCopied(false);
    setSelectedPlace(poi);
    if (closeTimeout) {
      clearTimeout(closeTimeout);
      setCloseTimeout(null);
    }
  };

  const handleInfoWindowMouseOver = () => {
    setIsMouseOverInfoWindow(true);
    if (closeTimeout) {
      clearTimeout(closeTimeout);
      setCloseTimeout(null);
    }
  };

  const handleInfoWindowMouseOut = () => {
    setIsMouseOverInfoWindow(false);
    const timeout = setTimeout(() => {
      setSelectedPlace(null);
    }, 500);
    setCloseTimeout(timeout);
  };

  const handleClickButton = (e) => {
    e.preventDefault(); // Si no hay href, prevenir la navegación
    setIsClicked(true);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderStars = (rate) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (rate >= 1) {
        stars.push(<FaStar key={i} color="#EC5306" />);
        rate -= 1;
      } else if (rate > 0) {
        stars.push(
          <FaStar key={i} color="#EC5306" style={{ opacity: rate }} />
        );
        rate = 0;
      } else {
        stars.push(<FaStar key={i} color="#CCCCCC" />);
      }
    }
    return stars;
  };

  const renderBasketballHoops = (cantAros) => {
    const hoops = [];
    for (let i = 0; i < parseInt(cantAros); i++) {
      hoops.push(<GiBasketballBasket key={i} color="#EC5306" size={25} />);
    }
    return hoops;
  };

  useEffect(() => {
    if (pois.length === 1) {
      setSelectedPlace(pois[0]); // Solo establece el selectedPlace si hay solo un poi
    }
  }, [pois]);

  useEffect(() => {
    return () => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }
    };
  }, [closeTimeout]);

  return (
    <>
      {pois.map((poi) => (
        <AdvancedMarker
          key={poi.key}
          position={poi.location}
          onMouseOver={() => handleMouseOver(poi)}
          onMouseOut={handleMouseOut}
          onClick={() => handleClick(poi)}
        >
          <Pin
            background={"#151414"}
            glyphColor={"#EC5306"}
            borderColor={"#EC5306"}
          />
        </AdvancedMarker>
      ))}
      {selectedPlace && (
        <InfoWindow
          position={selectedPlace.location}
          onCloseClick={() => setSelectedPlace(null)}
          onMouseOver={handleInfoWindowMouseOver}
          onMouseOut={handleInfoWindowMouseOut}
          style={{ width: "100%", overflowX: "hidden" }}
        >
          <div style={{ width: "100%" }}>
            {selectedPlace.images.length > 1 ? (
              <Slider {...sliderSettings}>
                {selectedPlace.images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Slide ${index}`}
                      style={{
                        maxWidth: "100%", // La imagen no excederá el ancho del contenedor
                        maxHeight: "40vh", // Establece la altura máxima
                        width: "auto", // La imagen mantiene su proporción
                        height: "auto", // La imagen mantiene su proporción
                        objectFit: "cover", // Cubre el espacio disponible sin distorsionar
                        borderRadius: 8, // Bordes redondeados opcionales
                      }}
                    />
                  </div>
                ))}
              </Slider>
            ) : (
              <div>
                <img
                  src={selectedPlace.images[0]}
                  alt="Single Image"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "40vh",
                    width: "auto",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: 8,
                  }}
                />
              </div>
            )}
            <h2>{selectedPlace.nombre}</h2>
            <p>
              <strong>Descripción:</strong> {selectedPlace.comentarios}
            </p>
            <p>
              <strong>Rating:</strong> {renderStars(selectedPlace.rate)}
            </p>
            <p>
              <strong>Cantidad de aros:</strong>{" "}
              {renderBasketballHoops(selectedPlace.cantAros)}
            </p>
            <p>
              <strong>Abierta siempre:</strong>{" "}
              {selectedPlace.abiertaSiempre ? "Sí" : "No"}
            </p>
            <p>
              <strong>Gente activa:</strong> {selectedPlace.genteActiva}
            </p>
            <p>
              <strong>Tiene cancha completa?</strong>{" "}
              {selectedPlace.canchaCompleta ? "Sí" : "No"}
            </p>
            <p
              style={
                selectedPlace.extraInfo
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <strong>Informacion Extra: </strong>
              {selectedPlace.extraInfo}
            </p>
            <HoverableButton
              href={`${selectedPlace.urls}`}
              openInNewTab
              style={
                selectedPlace.urls ? { display: "block" } : { display: "none" }
              }
            >
              Instagram de la comunidad
            </HoverableButton>

            <CopyToClipboard
              text={`${window.location.origin}${window.location.pathname}?canchaId=${selectedPlace.key}`}
              onCopy={() => setCopied(true)}
            >
              <a
                style={{
                  display: "block",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  color: isHovered || isClicked ? "white" : "#EC5306", // Cambia el texto a blanco en hover/click
                  marginTop: 10,
                  marginBottom: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  backgroundColor: isHovered || isClicked ? "#EC5306" : "white", // Cambia el fondo a naranja en hover/click
                  borderRadius: 10,
                  height: 30,
                  width: "99%",
                  border: "1.5px solid",
                  borderColor: "#EC5306",
                  marginTop: 10,
                  fontSize: 13,
                  fontWeight: "500",
                  transition: "all 0.3s ease", // Añade una transición suave de 0.3 segundos
                }}
                href="#"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClickButton}
              >
                Compartir cancha
              </a>
            </CopyToClipboard>

            <p
              style={
                copied
                  ? {
                      textAlign: "center",
                      fontSize: 13,
                      fontWeight: "400",
                      color: "#EC5306",
                    }
                  : { display: "none" }
              }
            >
              Enlace a la cancha copiado!
            </p>

            <HoverableButton
              href={`${selectedPlace.google_photos_url}`}
              openInNewTab
              style={{ display: "block" }}
            >
              Ver Mas Fotos
            </HoverableButton>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default function EncontraCancha(props) {
  const [barrio, setBarrio] = React.useState("");
  const [plaza, setPlaza] = React.useState("");
  const [polideportivo, setPolideportivo] = React.useState("");
  const [menuOpen, setMenuOpen] = React.useState(true);
  //const [selectedLocation, setSelectedLocation] = useState("");
  const [topRated, setTopRated] = useState(false);
  const [abiertaSiempre, setAbiertaSiempre] = useState(false);
  const [dosAros, setDosAros] = useState(false);
  const [conGente, setConGente] = useState(false);

  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { canchaId } = props;
  console.log("canchaId1: " + canchaId);

  useEffect(() => {
    const fetchLocations = async () => {
      const data = await getLocations();
      if (data) {
        // Transform the object to an array
        const locationsArray = Object.keys(data).map((key) => ({
          key: key,
          ...data[key],
        }));
        setLocations(locationsArray);

        console.log("canchaId2: " + canchaId);

        if (canchaId) {
          const filteredLocation = locationsArray.filter((location) => {
            console.log("Filtrando locación con id:", location.key);
            return location.key === canchaId;
          });
          setFilteredLocations(filteredLocation);
        } else {
          setFilteredLocations(locationsArray); // Sin filtro si no hay canchaId
        }
      }
    };

    fetchLocations();
  }, [canchaId]);

  /*
  useEffect(() => {
    console.log("canchaId2: " + canchaId);
    if (canchaId !== undefined && canchaId !== null) {
      const filteredLocation = locations.filter((location) => {
        console.log("Filtrando locación con id:", location.key);
        return location.key === canchaId;
      });

      setFilteredLocations(filteredLocation); // Esto ahora será un solo objeto o undefined si no hay coincidencias.

      console.log("setFilteredLocastion: " + JSON.stringify(filteredLocation));
    }
  }, locations);
*/

  const handleLocationChange = (event, locationType) => {
    const selectedValue = event.target.value;

    if (locationType === "barrio") {
      setBarrio(selectedValue);
    } else if (locationType === "plaza") {
      setPlaza(selectedValue);
    } else if (locationType === "polideportivo") {
      setPolideportivo(selectedValue);
    }
  };

  const handleCheckboxChange = (event, checkboxType) => {
    const checked = event.target.checked;

    if (checkboxType === "topRated") {
      setTopRated(checked);
    } else if (checkboxType === "abiertaSiempre") {
      setAbiertaSiempre(checked);
    } else if (checkboxType === "dosAros") {
      setDosAros(checked);
    } else if (checkboxType === "conGente") {
      setConGente(checked);
    }
  };

  useEffect(() => {
    setFilteredLocations(
      locations.filter((location) => {
        const barrioMatch = barrio === "" || location.barrio === barrio;
        const plazaMatch = plaza === "" || location.nombre === plaza;
        const polideportivoMatch =
          polideportivo === "" || location.nombre === polideportivo;
        const topRatedMatch = !topRated || location.rate >= 4;
        const abiertaSiempreMatch = !abiertaSiempre || location.abiertaSiempre;
        const dosArosMatch = !dosAros || location.cantAros >= 2;
        const conGenteMatch = !conGente || location.genteActiva > 0;

        return (
          barrioMatch &&
          plazaMatch &&
          polideportivoMatch &&
          topRatedMatch &&
          abiertaSiempreMatch &&
          dosArosMatch &&
          conGenteMatch
        );
      })
    );
  }, [
    barrio,
    plaza,
    polideportivo,
    topRated,
    abiertaSiempre,
    dosAros,
    conGente,
  ]);

  const uniqueBarrios = [
    ...new Set(locations.map((location) => location.barrio)),
  ];

  const plazaNames = locations
    .filter((location) => location.plaza)
    .map((location) => location.nombre);

  const polideportivosNombres = locations
    .filter((location) => location.polideportivo)
    .map((location) => location.nombre);

  return (
    <div>
      <Box
        sx={{
          display: { xs: menuOpen ? "flex" : "none", md: "flex" },
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          p: 4,
          bgcolor: "black",
          color: "white",
        }}
      >
        {/* Versión Mobile */}
        <Accordion
          sx={{
            display: { xs: "block", md: "none" },
            width: "100%",
            backgroundColor: "black",
            color: "#DFDCDC",
            borderBottom: 1,
            borderColor: "gray",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#DFDCDC" }} />}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              SELECCIONAR EL LUGAR
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth>
              <CustomInputLabel id="barrio-select-label">
                BARRIO
              </CustomInputLabel>
              <CustomSelect
                labelId="barrio-select-label"
                id="barrio-select"
                value={barrio}
                label="BARRIO"
                onChange={(event) => handleLocationChange(event, "barrio")}
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {uniqueBarrios.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <CustomInputLabel id="plaza-select-label">PLAZA</CustomInputLabel>
              <CustomSelect
                labelId="plaza-select-label"
                id="plaza-select"
                value={plaza}
                label="PLAZA"
                onChange={(event) => handleLocationChange(event, "plaza")}
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {plazaNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <CustomInputLabel id="polideportivo-select-label">
                POLIDEPORTIVO
              </CustomInputLabel>
              <CustomSelect
                labelId="polideportivo-select-label"
                id="polideportivo-select"
                value={polideportivo}
                label="POLIDEPORTIVO"
                onChange={(event) =>
                  handleLocationChange(event, "polideportivo")
                }
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {polideportivosNombres.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            display: { xs: "block", md: "none" },
            width: "100%",
            mt: 2,
            backgroundColor: "black",
            color: "#DFDCDC",
            borderBottom: 1,
            borderColor: "gray",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#DFDCDC" }} />}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              FILTROS DE BÚSQUEDA
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                "& .MuiFormControlLabel-label": {
                  fontWeight: "bold",
                },
              }}
            >
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={topRated}
                    onChange={(event) =>
                      handleCheckboxChange(event, "topRated")
                    }
                  />
                }
                label="TOP RATED"
              />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={dosAros}
                    onChange={(event) => handleCheckboxChange(event, "dosAros")}
                  />
                }
                label="DOS AROS"
              />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={abiertaSiempre}
                    onChange={(event) =>
                      handleCheckboxChange(event, "abiertaSiempre")
                    }
                  />
                }
                label="ABIERTAS 24/7"
              />
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={conGente}
                    onChange={(event) =>
                      handleCheckboxChange(event, "conGente")
                    }
                  />
                }
                label="CON GENTE"
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>

        {/* Versión Desktop */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              gap: 5,
              width: "70%",
            }}
          >
            <FormControl fullWidth>
              <CustomInputLabel id="barrio-select-label">
                BARRIO
              </CustomInputLabel>
              <CustomSelect
                labelId="barrio-select-label"
                id="barrio-select"
                value={barrio}
                label="BARRIO"
                onChange={(event) => handleLocationChange(event, "barrio")}
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {uniqueBarrios.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>

            <FormControl fullWidth>
              <CustomInputLabel id="plaza-select-label">PLAZA</CustomInputLabel>
              <CustomSelect
                labelId="plaza-select-label"
                id="plaza-select"
                value={plaza}
                label="PLAZA"
                onChange={(event) => handleLocationChange(event, "plaza")}
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {plazaNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>

            <FormControl fullWidth>
              <CustomInputLabel id="polideportivo-select-label">
                POLIDEPORTIVO
              </CustomInputLabel>
              <CustomSelect
                labelId="polideportivo-select-label"
                id="polideportivo-select"
                value={polideportivo}
                label="POLIDEPORTIVO"
                onChange={(event) =>
                  handleLocationChange(event, "polideportivo")
                }
              >
                <MenuItem value="">
                  <em>Todos</em>
                </MenuItem>
                {polideportivosNombres.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </Box>

          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              "& .MuiFormControlLabel-label": {
                fontWeight: "bold",
              },
              marginLeft: 10,
            }}
          >
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={topRated}
                  onChange={(event) => handleCheckboxChange(event, "topRated")}
                />
              }
              label="TOP RATED"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={dosAros}
                  onChange={(event) => handleCheckboxChange(event, "dosAros")}
                />
              }
              label="DOS AROS"
            />
          </FormGroup>

          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              "& .MuiFormControlLabel-label": {
                fontWeight: "bold",
              },
            }}
          >
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={abiertaSiempre}
                  onChange={(event) =>
                    handleCheckboxChange(event, "abiertaSiempre")
                  }
                />
              }
              label="ABIERTAS 24/7"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={conGente}
                  onChange={(event) => handleCheckboxChange(event, "conGente")}
                />
              }
              label="CON GENTE"
            />
          </FormGroup>
        </Box>
      </Box>
      <div style={{ width: "100%", height: "100vh" }}>
        <APIProvider
          apiKey={GOOGLE_MAPS_API_KEY}
          onLoad={() => console.log("Maps API has loaded.")}
        >
          <Map
            defaultZoom={13}
            defaultCenter={{ lat: -34.61315, lng: -58.37723 }}
            mapId={"8a45de67aeece53b"}
            /*
            onCameraChanged={(ev) =>
              console.log(
                "camera changed:",
                ev.detail.center,
                "zoom:",
                ev.detail.zoom
              )
            }
              */
          >
            <PoiMarkers pois={filteredLocations} />
          </Map>
        </APIProvider>
      </div>
    </div>
  );
}
